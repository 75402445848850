var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ExamEditDialog from './ExamEditDialog';
import { useDataProvider, useNotify, useRefresh, useTranslate, } from "react-admin";
import { useMediaQuery, Divider, Typography, Grid, } from "@mui/material";
import { general } from "../utils/componentWizard";
import SelectedRowLeftColumn from "./SelectedRowLeftColumn";
import FileUpload from "../utils/FileUpload/FileUpload";
import FPComp from "./fp/FP";
import DataImport from "./dataImport/dataImport";
import AIResultsList from "./aiResults/AIResults";
import EditDialog from "../subjectPage/EditDialog";
import { updateNewExamDates, } from "../utils/helper";
import dummyData from "./fp/helper/dummyData";
import { TranslateExamsClassHelper } from "../utils/TranslateClassHelper";
import { BODY_GRID_PADDING, NORMAL_GRID_SPACE, } from "../styles/styleConstants.data";
import "../styles/global.module.css";
import { acl } from "../utils/dummyData";
import { LoadingAnimation } from "../utils/loadingAnimation";
var isNotEven = function (numb) {
    return numb % 2 !== 0;
};
var ComponentSelection = function (_a) {
    var bgColor = _a.bgColor, text = _a.text, setCurrentSubjectSection = _a.setCurrentSubjectSection, setImage = _a.setImage, setDummyCompList = _a.setDummyCompList;
    var translate = useTranslate();
    return (_jsx(Grid, { item: true, xs: 4, paddingY: 1, display: "flex", justifyContent: "center", alignItems: "center", style: { backgroundColor: bgColor !== null && bgColor !== void 0 ? bgColor : "white" }, onClick: function () {
            if (text === "Data Import") {
                return;
            }
            if (text === "AI Results") {
                setDummyCompList([{ name: "AI Results", bgColor: "white" }]);
            }
            setImage(null);
            setCurrentSubjectSection(text);
        }, sx: { boxShadow: "0 0 2px 0.1px gray" }, children: _jsx(Typography, { className: "small-text", children: translate("components.".concat(text.toLowerCase().replace(" ", "_"))) }) }));
};
var ExamList = function (props) {
    var _a, _b;
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState("AI Results"), currentSubjectSection = _d[0], setCurrentSubjectSection = _d[1]; //[back][for fp] - make it "AI Results" later
    var _e = useState([
        { name: "AI Results", bgColor: "white" },
        // { name: "Data Import" },
        // { name: "Questionnaire Results" },
        // { name: "FP" },
        // { name: "OCT" }, { name: "FP + OCT" }
    ]), dummyCompList = _e[0], setDummyCompList = _e[1];
    var _f = useState({
        // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
        // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
        subject_id: "na"
    }), filters = _f[0], setFilters = _f[1];
    var _g = useState({}), params = _g[0], setParams = _g[1];
    // left panel
    var _h = useState([]), selectedRow = _h[0], setSelectedRow = _h[1];
    var _j = useState(false), dialogOpened = _j[0], setDialogOpened = _j[1];
    // AI result
    var _k = useState(), examDialogData = _k[0], setExamDialogData = _k[1]; //onclick AI results list - the dialog
    var _l = useState(false), examDialogOpened = _l[0], setExamDialogOpened = _l[1]; //onclick AI results list - open dialog
    var _m = useState({
        customId: null,
        hkid_id: null,
    }), selectedHKID = _m[0], setSelectedHKID = _m[1];
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var location = useLocation();
    var navigate = useNavigate();
    // for Image upload
    var _o = useState([]), curRowImgs = _o[0], setCurRowImgs = _o[1]; //imgs array - only img in exams table's row
    // const [curRowImgs, setCurRowImgs] = useState([ //[back][for fp]
    //     {
    //         right: true,
    //         gradable: true,
    //         comment: "",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 1,
    //         center: true
    //     },
    //     {
    //         right: false,
    //         gradable: true,
    //         comment: "",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 2,
    //         center: true
    //     },
    // ]);
    var _p = useState(null), currentImgUploadRow = _p[0], setCurrentImgUploadRow = _p[1]; //row in exams table
    var _q = useState(null), image = _q[0], setImage = _q[1]; //[back][for fp] - make it null later
    var _r = useState(null), imageInfos = _r[0], setImageInfos = _r[1];
    var _s = useState(null), analyzedResultInfo = _s[0], setAnalyzedResultInfo = _s[1];
    var _t = useState(dummyData), analyzedImagesData = _t[0], setAnalyzedImagesData = _t[1];
    var _u = useState(false), isLoadingUpload = _u[0], setIsLoadingUpload = _u[1];
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _v = useState([]), data = _v[0], setData = _v[1];
    var _w = useState([]), examDatesData = _w[0], setExamDatesData = _w[1];
    var _x = useState({ confidence_level: 0 }), firstIndexExam = _x[0], setFirstIndexExam = _x[1];
    var resource = "exams";
    //for AI analyze for image upload
    var _y = useState(false), isLoadingFPAI = _y[0], setIsLoadingFPAI = _y[1];
    var _z = useState(60), loadingFPAITime = _z[0], setLoadingFPAITime = _z[1];
    var _0 = useState(acl), accessControlList = _0[0], setAccessControlList = _0[1];
    var _1 = useState(0), gens = _1[0], setGens = _1[1]; //quota of gens for AI analysis report
    var translateClassHelper = new TranslateExamsClassHelper(translate);
    var isMobileScreen = useMediaQuery("(max-width: 750px)");
    useEffect(function () {
        // translate the labels
        var newSelectedRow = __assign({}, selectedRow);
        // setSelectedRow({...selectedRow,gender:translate(`pages.subject.fields.gender`), })
    }, [translate]);
    document.body.style.zoom = "100%";
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var sessonStorageJSON, currentHKIDID, currentHKID, locationData, getOneParams, params, noFilterParams, newParams, orgParams, orgData, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
                    currentHKIDID = (_b = localStorage.getItem("hkid_id")) !== null && _b !== void 0 ? _b : null;
                    currentHKID = (_c = localStorage.getItem("customId")) !== null && _c !== void 0 ? _c : null;
                    setSelectedHKID({ customId: currentHKID, hkid_id: currentHKIDID });
                    if (sessonStorageJSON) {
                        setFilters(__assign(__assign(__assign({}, filters), sessonStorageJSON), { subject_id: currentHKIDID !== null && currentHKIDID !== void 0 ? currentHKIDID : "" }));
                    }
                    locationData = location === null || location === void 0 ? void 0 : location.state;
                    sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign(__assign({}, filters), sessonStorageJSON), { subject_id: currentHKIDID })));
                    // if is redirect, set up the redirected data
                    if (locationData) {
                        setSelectedRow(__assign({ dialogType: "Edit" }, locationData));
                    }
                    // if is refresh/enter url, get one
                    else {
                        getOneParams = { id: currentHKIDID };
                        dataProvider.getOne("subjects", getOneParams).then(function (data) {
                            setSelectedRow(__assign(__assign({ dialogType: "Edit" }, data.data), { hkid_id: currentHKIDID }));
                            refresh();
                            notify(translate("pages.exam.notify.get"));
                        });
                    }
                    params = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "exam_date", order: "DESC" },
                        filter: {
                            // exam_id: currentHKIDID,
                            // customId: currentHKID,  //[back] later switch to customId
                            // createby: 1,
                            // givenId: currentHKIDID, //[back] later switch to real id - id for db, not customId
                            // // ...filters
                            subject_id: selectedRow.subject_id,
                        },
                    };
                    noFilterParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "exam_date", order: "DESC" },
                        filter: {
                            subject_id: currentHKIDID,
                        },
                    };
                    dataProvider
                        .getListNoFilter(resource, noFilterParams)
                        .then(function (rtnData) {
                        var _a, _b, _c;
                        setData((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) !== null && _a !== void 0 ? _a : []);
                        setFirstIndexExam((_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data[0]) !== null && _b !== void 0 ? _b : {});
                        setExamDatesData(updateNewExamDates((_c = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) !== null && _c !== void 0 ? _c : []));
                        refresh();
                        //fetch data with params after for table (AI Results)
                        params.filter = __assign(__assign({}, params.filter), filters);
                        return dataProvider.getList(resource, params);
                    })
                        .then(function () {
                        refresh();
                        setIsLoading(false);
                        notify(translate("pages.exam.notify.get"));
                    });
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    newParams = {};
                    orgParams = {
                        pagination: { page: 1, perPage: 200 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("organizations", orgParams)];
                case 2:
                    orgData = (_e.sent());
                    orgData = (_d = orgData === null || orgData === void 0 ? void 0 : orgData.data) === null || _d === void 0 ? void 0 : _d.map(function (item) { return ({ id: item.id, name: item.orgName, value: item.id }); });
                    newParams['organizations'] = orgData;
                    setParams(newParams);
                    getACL();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _e.sent();
                    notify(translate('error.organization.getParamsFailed'), {
                        type: 'error',
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        console.log(" ");
    }, [filters]);
    // for AI result list [section]
    var closeDialog = function () {
        setDialogOpened(false);
    };
    // after "subject" edit dialog pressing "submit"
    var reloadRecord = function (values, dialogType) {
        try {
            var params_1 = {
                data: {
                    id: values.id,
                    customId: values.customId,
                    subject_customId: values.customId, //[back] later switch to customId,
                    hkid_id: selectedHKID.hkid_id,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    gender: values.gender,
                    educationLevel: values.educationLevel,
                    dateOfBirth: values.dateOfBirth,
                    createby: 1,
                    givenId: values.id, //[back] later switch to real id - id for db, not customId
                },
            };
            dataProvider.update("subjects", params_1).then(function () {
                refresh();
                notify(translate("pages.subject.notify.updated"));
                closeDialog();
                setSelectedRow(params_1.data);
                getACL();
            });
        }
        catch (err) {
            console.log(err, "==err");
            // dispatch(actionTypes.SNACKBAR_ERROR);
            if (error == "HttpError2: A subject with this id already exists in this organization. Please use a unique subject id" ||
                error == "HttpError2: There is same id subject in your organization") {
                notify(translate("error.subject.subId"), {
                    type: 'error',
                });
            }
            else if (error == "HttpError2: This organization id does not belong to any organizations") {
                notify(translate("error.subject.org"), {
                    type: 'error',
                });
            }
            else {
                notify(translate("pages.subject.notify.addFailed"), {
                    type: 'error',
                });
            }
        }
        ;
    };
    // for left panel exam [section]
    var closeExamDateDialog = function () {
        setExamDateDialogOpened(false);
    };
    var openExamDateDialog = function () {
        //open dialog
        setExamDateDialogOpened(true);
    };
    var reloadCreateExamDateRecord = function (values, viewCase) {
        var _a;
        try {
            setCurrentImgUploadRow(__assign(__assign({}, values), { hkid_id: selectedHKID.hkid_id }));
            if (((_a = values === null || values === void 0 ? void 0 : values.fp_imgs) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                // preview
                setCurrentSubjectSection("");
                setCurRowImgs(values.fp_imgs);
                setImage(values.fp_imgs[0]);
            }
            else {
                // upload
                setCurrentSubjectSection("Data Import");
                if (viewCase === "preview") {
                    setCurRowImgs(values.fp_imgs);
                }
            }
        }
        catch (err) {
            console.log(err, "==err");
            notify(translate('pages.exam.notify.addFailed'), {
                type: 'error',
            });
        }
    };
    // for AI results list [section]
    var closeExamEditDialog = function () {
        setExamDialogOpened(false);
    };
    var openExamEditDialog = function (record) {
        var _a, _b, _c, _d;
        //open dialog
        setExamDialogData({
            dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create",
            id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null,
            customId: (_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.customId) !== null && _b !== void 0 ? _b : null,
            ai_model: (_c = record === null || record === void 0 ? void 0 : record.ai_model) !== null && _c !== void 0 ? _c : null,
            eye: (_d = record === null || record === void 0 ? void 0 : record.eye) !== null && _d !== void 0 ? _d : null,
            hkid_id: selectedHKID.hkid_id,
        });
        // formik?.current?.resetForm();
        setExamDialogOpened(true);
    };
    var reloadExamEditRecord = function (values, dialogType, submitType) {
        try {
            var params_2 = {
                data: {
                    subject_customId: selectedHKID.customId,
                    // customId: values.customId,
                    // customId: values.hkid_id,
                    exam_date: values.exam_date,
                    // eye: values.eye,
                    // ai_model: values.ai_model,
                },
            };
            switch (dialogType) {
                case "Create":
                    params_2.data.eid = values.id;
                    // params.data.customId = values.customId;
                    params_2.data.customId = values.hkid_id;
                    params_2.data.parentGivenId = values.customId;
                    // params.data.parentGivenId = values.hkid_id;
                    params_2.data.givenId = values.id;
                    dataProvider.create(resource, params_2).then(function (returnData) {
                        var updatedData = __spreadArray([], data, true);
                        updatedData.push(returnData.data);
                        setData(updatedData);
                        setExamDatesData(updateNewExamDates(updatedData !== null && updatedData !== void 0 ? updatedData : []));
                        // setCurrentSubjectSection("Data Import")
                        if (submitType === "submitWithImport") {
                            reloadCreateExamDateRecord(returnData.data);
                            setDummyCompList([
                                { name: "AI Results", bgColor: "#efefef" },
                                { name: "Data Import", bgColor: "white" },
                            ]);
                        }
                        refresh();
                        notify(translate("pages.exam.notify.added"));
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        console.error(error);
                        if (error.message.includes("not found")) {
                            notify(translate("error.subject.NotFoundException"), {
                                type: 'error',
                            });
                        }
                        else {
                            notify(translate("pages.exam.notify.updateFailed"), {
                                type: 'error',
                            });
                        }
                    });
                    // if clicked import data
                    break;
                case "Edit":
                    if (values.ai_model) {
                        params_2.data.ai_model = values.ai_model;
                    }
                    dataProvider
                        .updateAttribute(resource, params_2, values.hkid_id, values.id)
                        .then(function () {
                        // [back] problem here
                        var fetchParams = {
                            pagination: { page: 1, perPage: 25 },
                            sort: { field: "exam_date", order: "DESC" },
                            filter: {
                                // exam_id: selectedRow.id,
                                // customId: selectedRow.customId,
                                // createby: 1,
                                // givenId: selectedRow.id,
                                // id: values.id
                                subject_id: selectedRow.id,
                            },
                        };
                        return dataProvider.getListNoFilter(resource, fetchParams);
                    })
                        .then(function (data) {
                        var _a, _b;
                        setData((_a = data === null || data === void 0 ? void 0 : data.data) !== null && _a !== void 0 ? _a : []);
                        setExamDatesData(updateNewExamDates((_b = data === null || data === void 0 ? void 0 : data.data) !== null && _b !== void 0 ? _b : []));
                        refresh();
                        notify(translate("pages.exam.notify.updated"));
                    })
                        .catch(function (error) {
                        // Handle any errors that occurred
                        console.error(error);
                        notify(translate("pages.exam.notify.updateFailed"), {
                            type: 'error',
                        });
                    });
                    break;
            }
        }
        catch (err) {
            console.log(err, "==err");
            // dispatch(actionTypes.SNACKBAR_ERROR);
            notify(translate('pages.exam.notify.updateFailed'), {
                type: 'error',
            });
        }
    };
    var delExamEditAction = function (values) {
        var params = {
            data: {
                eid: values.id,
                subject_customId: values.subject_customId, //[back] later switch to customId
                // customId: values.subject_customId,
                customId: values.hkid_id,
                // givenId: values.id,
                // parentGivenId: values.subject_customId,
            },
        };
        dataProvider
            .deleteAttribute(resource, params, selectedHKID.hkid_id, values.id)
            .then(function () {
            var updatedData = data;
            var index = updatedData.findIndex(function (obj) { return obj.id === values.id; });
            if (index !== -1) {
                updatedData.splice(index, 1);
            }
            setData(updatedData);
            setExamDatesData(updateNewExamDates(updatedData !== null && updatedData !== void 0 ? updatedData : []));
            refresh();
            notify(translate("pages.exam.notify.deleted"));
        }).catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            notify(translate("error.exam.delFailed"), {
                type: 'error',
            });
        });
    };
    // get ACL for org (top bar)
    var getACL = function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem("auth"))) !== null && _a !== void 0 ? _a : {};
        var settingsParams = {};
        if (cachedAuth) {
            settingsParams.id = cachedAuth.id;
            dataProvider
                .getOneWithoutDataJSON("users", settingsParams)
                .then(function (rtnData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                var newACList = ((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _a === void 0 ? void 0 : _a.ACL) || {};
                setAccessControlList(newACList);
                setGens((_b = rtnData === null || rtnData === void 0 ? void 0 : rtnData.gens) !== null && _b !== void 0 ? _b : 0);
                localStorage.setItem("appBarOrg", (_f = (_e = (_d = (_c = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _c === void 0 ? void 0 : _c.info) === null || _d === void 0 ? void 0 : _d['current_organization']) === null || _e === void 0 ? void 0 : _e[1]) !== null && _f !== void 0 ? _f : "N/A");
                localStorage.setItem("userOrgId", (_k = (_j = (_h = (_g = rtnData === null || rtnData === void 0 ? void 0 : rtnData.profile) === null || _g === void 0 ? void 0 : _g.info) === null || _h === void 0 ? void 0 : _h['current_organization']) === null || _j === void 0 ? void 0 : _j[0]) !== null && _k !== void 0 ? _k : "N/A");
                refresh();
            })
                .catch(function (error) {
                // Handle any errors that occurred
                console.error(error);
                localStorage.setItem("appBarOrg", "N/A");
                localStorage.setItem("userOrgId", "N/A");
                notify(translate('error.ACL.getACLFailed'), {
                    type: 'error',
                });
            });
        }
    };
    // left panel - onclick "preview" action
    var handlePreviewExamDate = function (values, subId) {
        var examDate = new Date(values.exam_date).toLocaleDateString("zh-CN");
        var sessionStorageParams = {
            examFromDate: examDate,
            examToDate: examDate,
            subject_id: subId,
        };
        // set examFromDate as filter only
        setFilters({});
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(sessionStorageParams));
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "exam_date", order: "DESC" },
            filter: __assign(__assign({}, sessionStorageParams), { subject_id: selectedRow.subject_id }),
        };
        setFilters(sessionStorageParams);
        dataProvider.getList(resource, params).then(function (data) {
            refresh();
            setFilters(sessionStorageParams);
            notify(translate("pages.exam.notify.get"));
        });
    };
    return (_jsxs(Grid, { container: true, padding: BODY_GRID_PADDING, columnGap: NORMAL_GRID_SPACE, rowGap: 2, direction: { xs: "row", lg: "column" }, children: [_jsx(Grid, { container: true, xs: 12, lg: 3, sx: {
                    // position: 'sticky',
                    top: 0,
                    height: 'fit-content'
                }, children: _jsx(SelectedRowLeftColumn, { AccessControlList: accessControlList, translate: translate, selectedRow: selectedRow, setSelectedRow: setSelectedRow, 
                    // data={data}
                    firstIndexExam: firstIndexExam, data: examDatesData, examDatesData: examDatesData, setDialogOpened: setDialogOpened, openExamEditDialog: openExamEditDialog, page: resource, handlePreviewExamDate: handlePreviewExamDate }) }), _jsxs(Grid, { xs: 12, lg: 9, alignContent: "start", justifyItems: "baseline", direction: "row", rowGap: 1, sx: { width: "100%" }, children: [_jsx(Grid, { xs: 12, children: _jsx(general.breadcrumb, { trail: [
                                translate("breadcrumb.main"),
                                translate("pages.subject.name"),
                                // `${blueIDHelper(selectedRow?.customId) ?? ""}`,
                                "".concat((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.customId) !== null && _a !== void 0 ? _a : ""),
                            ] }) }), _jsx(Grid, { xs: 12, children: _jsx(Typography, { className: "normal-text large-bold", children: "".concat(translate("pages.subject.name"), " ").concat((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.customId) !== null && _b !== void 0 ? _b : "") }) }), _jsx(Grid, { xs: 12, children: _jsx(Divider, {}) }), _jsx(Grid, { container: true, xs: 12, children: dummyCompList.map(function (el, index) {
                            var _a;
                            return (_jsx(ComponentSelection, { text: el === null || el === void 0 ? void 0 : el.name, bgColor: (_a = el === null || el === void 0 ? void 0 : el.bgColor) !== null && _a !== void 0 ? _a : null, setImage: setImage, setCurrentSubjectSection: setCurrentSubjectSection, setDummyCompList: setDummyCompList }));
                        }) }), currentSubjectSection === "OCT" && (_jsx(Grid, { xs: 12, children: _jsx(FileUpload, {}) })), (currentSubjectSection === "Data Import" || image) &&
                        curRowImgs.length < 10 && (_jsx(Grid, { xs: 12, width: "100%", children: _jsx(DataImport, { translate: translate, 
                            //check if have uploads
                            haveUploads: image ? true : false, setImage: setImage, image: image, imageInfos: imageInfos, setImageInfos: setImageInfos, analyzedResultInfo: analyzedResultInfo, setAnalyzedResultInfo: setAnalyzedResultInfo, analyzedImagesData: analyzedImagesData, isLoadingUpload: isLoadingUpload, setIsLoadingUpload: setIsLoadingUpload, currentImgUploadRow: currentImgUploadRow, setCurRowImgs: setCurRowImgs, curRowImgs: curRowImgs }) })), image && (_jsx(Grid, { xs: 12, width: "100%", children: _jsx(FPComp, { gens: gens, getACL: getACL, translate: translate, setImage: setImage, image: image, imageInfos: imageInfos, setImageInfos: setImageInfos, analyzedResultInfo: analyzedResultInfo, analyzedImagesData: analyzedImagesData, setAnalyzedResultInfo: setAnalyzedResultInfo, setCurrentSubjectSection: setCurrentSubjectSection, curRowImgs: curRowImgs, setCurRowImgs: setCurRowImgs, currentImgUploadRow: currentImgUploadRow, selectedRow: selectedRow, isLoadingUpload: isLoadingUpload, setIsLoadingUpload: setIsLoadingUpload, setDummyCompList: setDummyCompList, loadingFPAITime: loadingFPAITime, setIsLoadingFPAI: setIsLoadingFPAI, setLoadingFPAITime: setLoadingFPAITime, isLoadingFPAI: isLoadingFPAI }) })), currentSubjectSection === "AI Results" && !isLoadingFPAI && (_jsx(Grid, { xs: 12, children: _jsx(AIResultsList, { isMobileScreen: isMobileScreen, isTabScreen: isTabScreen, translate: translate, isLoading: isLoading, setIsLoading: setIsLoading, selectedRow: selectedRow, setSelectedRow: setSelectedRow, filters: filters, setFilters: setFilters, delExamEditAction: delExamEditAction, reloadCreateExamDateRecord: reloadCreateExamDateRecord, setCurRowImgs: setCurRowImgs, openExamEditDialog: openExamEditDialog, setData: setData, setDummyCompList: setDummyCompList }) })), isLoadingFPAI && (_jsx(LoadingAnimation, {}))] }), dialogOpened && (_jsx(Grid, { xs: 9, children: _jsx(EditDialog, { isMobileScreen: isMobileScreen, opened: dialogOpened, data: selectedRow, params: params, 
                    // openDialog={openDialog}
                    closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate }) })), examDialogOpened && (_jsx(Grid, { xs: 9, children: _jsx(ExamEditDialog, { isMobileScreen: isMobileScreen, opened: examDialogOpened, data: examDialogData, 
                    // openDialog={openDialog}
                    translate: translate, closeDialog: closeExamEditDialog, reloadRecord: reloadExamEditRecord }) }))] }));
};
export default ExamList;
