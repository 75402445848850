var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import logoImage from '../assets/logos/iCogSci Logo_Design 1a-5_v2.0(241209)-04.png';
export var generateInvoice = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve) {
                var doc = new jsPDF();
                // Define colors
                var mainBlue = '#37A1E2';
                // Add blue header bar (flat design) - reduced height to match image
                var headerHeight = 10; // Made even smaller
                doc.setFillColor(mainBlue);
                doc.rect(0, 0, doc.internal.pageSize.width, headerHeight, 'F');
                // Add angled cutout at bottom right of header
                doc.setFillColor(255, 255, 255); // White
                doc.triangle(doc.internal.pageSize.width, headerHeight, doc.internal.pageSize.width - 40, headerHeight, doc.internal.pageSize.width, 0, 'F');
                // Add logo in top left (matching size from image)
                doc.addImage(logoImage, 'PNG', 15, 8, 40, 40); // Moved down to y=8
                // Add "INVOICE" text in blue - positioned to match the image
                doc.setTextColor(mainBlue);
                doc.setFontSize(40); // Increased from 28 to 32
                doc.setFont('helvetica', 'bold');
                doc.text('INVOICE', 150, 30); // Moved down to y=30
                // Bill To section
                doc.setTextColor(0, 0, 0);
                doc.setFontSize(12);
                doc.setFont('helvetica', 'bold');
                doc.text('Bill To:', 15, 60); // Adjusted starting position
                doc.setFont('helvetica', 'normal');
                doc.text("".concat(data.organization.orgName), 15, 70);
                doc.text("".concat(data.buyer.First_name, " ").concat(data.buyer.Last_name), 15, 80);
                doc.text("".concat(data.buyer.email), 15, 90);
                doc.text("".concat(data.paymentInfo.address.country), 15, 100);
                // Invoice details
                doc.setFont('helvetica', 'bold');
                doc.text('Invoice #', 120, 60);
                doc.text('Date', 120, 70);
                doc.setFont('helvetica', 'normal');
                doc.text("".concat(data.id.slice(0, 8)), 170, 60, { align: 'right' });
                doc.text("".concat(format(new Date(data.created_at), 'dd/MM/yyyy')), 170, 70, { align: 'right' });
                // Invoice table header
                doc.setFillColor(mainBlue);
                doc.rect(15, 120, 70, 10, 'F');
                doc.rect(85, 120, 35, 10, 'F');
                doc.rect(120, 120, 35, 10, 'F');
                doc.rect(155, 120, 35, 10, 'F');
                doc.setTextColor(255, 255, 255);
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(11);
                doc.text('Package', 25, 127);
                doc.text('Quantity', 95, 127);
                doc.text('Quota', 130, 127);
                doc.text('Total', 165, 127);
                // Item row
                doc.setTextColor(0, 0, 0);
                doc.setFont('helvetica', 'normal');
                doc.text("".concat(data.product), 25, 140);
                doc.text('1', 100, 140, { align: 'center' });
                doc.text("".concat(data.quota), 135, 140, { align: 'center' });
                doc.text("".concat(data.charge.toFixed(2)), 175, 140, { align: 'right' });
                // Add line under item row
                doc.setDrawColor(0);
                doc.line(15, 150, 190, 150);
                // Subtotal, fee and total (right-aligned as per image)
                doc.setFont('helvetica', 'normal');
                doc.text('Subtotal', 140, 160);
                doc.text("$".concat(data.charge.toFixed(2)), 175, 160, { align: 'right' });
                // doc.text('Stripe Fee', 140, 180);
                // doc.text(`$${data.stripeFee.toFixed(2)}`, 175, 180, { align: 'right' });
                // Add line above total
                doc.setDrawColor(0);
                doc.line(135, 168, 190, 168);
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(16); // h2 size is typically 16px
                doc.text('Total', 140, 175);
                doc.text("$".concat((data.charge + 0).toFixed(2)), 175, 175, { align: 'right' });
                // Footer
                var footerHeight = 15; // Smaller footer height
                doc.setFillColor(mainBlue);
                doc.rect(0, doc.internal.pageSize.height - footerHeight, doc.internal.pageSize.width, footerHeight, 'F');
                doc.setTextColor(255, 255, 255);
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(10);
                doc.text('Thank you for your business!', doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 5, { align: 'center' });
                // Convert to blob using the correct method
                var pdfData = doc.output('arraybuffer');
                var blob = new Blob([pdfData], { type: 'application/pdf' });
                resolve(blob);
            })];
    });
}); };
