var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Papa from 'papaparse';
import { dbDateHelper } from '../../utils/helper';
// download csv
var JSONExport = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var csvData, newDataGridHeader, csvHeader, subjects, newData;
    var data = _b.data, dataGridHeader = _b.dataGridHeader, onlyGroupSubjectData = _b.onlyGroupSubjectData;
    return __generator(this, function (_c) {
        newDataGridHeader = __spreadArray([], dataGridHeader, true);
        csvHeader = newDataGridHeader[0] ? Object.values(newDataGridHeader[0]).slice(0, -2) : [];
        subjects = Array.isArray(data) ? data : [data];
        newData = subjects.map(function (subject) {
            var filteredSubject = (function (_a) {
                var _b, _c;
                var customId = _a.customId, firstname = _a.firstname, lastname = _a.lastname, gender = _a.gender, educationLevel = _a.educationLevel, dateOfBirth = _a.dateOfBirth, exams = _a.exams;
                return ({
                    customId: "=\"".concat(customId, "\""),
                    firstname: "=\"".concat(firstname, "\""),
                    lastname: "=\"".concat(lastname, "\""),
                    gender: "=\"".concat(gender, "\""),
                    educationLevel: "=\"".concat(educationLevel, "\""),
                    dateOfBirth: dbDateHelper(dateOfBirth),
                    confidenceLevel: "=\"".concat((_c = (_b = exams === null || exams === void 0 ? void 0 : exams[0]) === null || _b === void 0 ? void 0 : _b.confidence_level) !== null && _c !== void 0 ? _c : "N/A", "\""),
                });
            })(subject);
            return Object.values(filteredSubject);
        });
        csvData = __spreadArray([csvHeader], newData, true);
        return [2 /*return*/, Papa.unparse(csvData)];
    });
}); };
export default JSONExport;
